import * as React from "react"
import { Layout } from "../components/layout"
import { heading, paragraph, container } from "./404.module.css"
import { Seo } from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <div className=" w-5/6 md:w-3/5 pt-[20vh] md:pt-[20px]  mx-auto">
        <h1 className={heading}>Contact</h1>
        {/* 
Instagram : dasyori

Tiktok : dasyori

Customer Service : customerservice@yori.nyc
 */}    <br />
        <p className="flex flex-col">
          <a
            className="hover:text-red-500"
            href="mailto:customerservice@yori.nyc"
          >
            Customer Service : customerservice@yori.nyc
          </a>
          <a
            className="hover:text-red-500"
            href="https://tiktok.com/@dasyori">TikTok : dasyori</a>
             <a
            className="hover:text-red-500"
            href="https://instagram.com/dasyori">Instagram : dasyori</a>
        </p>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo />
